@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Rubik', sans-serif !important;

}

label {
    margin-bottom: 0 !important;
}

#root {
    min-height: 100vh;
    background: linear-gradient(113.75deg, #C4D3F9 -0.47%, #D9F0F8 32.59%, #E5EDF0 57.74%, #F1E6DC 100.39%);
}

h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #474747;
}

.btn {
    min-width: 140px;
}

.page-welcome {
    padding: 55px 70px;
    background-color: white;
    border-radius: 10px;
}

.page-welcome h2 {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 47px !important;
    color: #474747 !important;
}

.page-welcome p {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #474747;
}

.icon-warning {
    cursor: pointer;
    width: max-content;
}

.custom-modal-warning {
    display: none;
    position: absolute;
    right: 0;
    z-index: 99999;
    top: 35px;
    width: 500px;
}

.icon-warning :hover ~ .custom-modal-warning {
    display: block;
}
.icon-edit {
    cursor: pointer;
    border-radius: 50%;
    color: #474747;
    padding: 2px;
}
.icon-edit:hover {
    color: #FFFFFF;
    background-color: #0A84FF;
    font-weight: normal;
}
.page-welcome p {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #474747;
}

.page-welcome button {
    margin-top: 40px;
}

input[type="text"] {
    font-family: inherit; /* 1 */
    font-size: inherit; /* 1 */
    line-height: inherit; /* 1 */
    margin: 0; /* 2 */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
    max-width: 1370px;
    margin: 0 auto;
}

h2 {
    font-size: 25px !important;
    line-height: 110.9% !important;
    color: #323A44 !important;
    font-weight: normal !important;
    text-align: start;
}

.card {
    box-sizing: border-box;
    padding: 30px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.input {
    height: 140px;
    background: #F5F7FA;
    border: 1px dashed #2F80ED;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2F80ED;
}

.text-field {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    padding: 17px;
}

.input-none {
    display: none;
}

header {
    width: 100%;
    background: rgba(250, 251, 251, 0.6);
    padding: 10px 0;
    margin-bottom: 25px;
}

.admin-icon-user {
    position: relative;
    text-align: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
}

.admin-icon-user img {
    width: 38px;
    height: 38px;
}

/*.admin {*/
/*    display: flex;*/
/*    gap: 10px;*/
/*    align-items: center;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*}*/

/*.admin p {*/
/*    margin: 0;*/
/*}*/

/*.amin-menu {*/
/*    position: absolute;*/
/*    top: 50px;*/
/*    right: 10px;*/
/*    background-color: #FAFBFB;*/
/*    padding: 15px 30px;*/
/*    border-radius: 4px;*/
/*    z-index: 99999;*/
/*}*/

/*.amin-menu p {*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.amin-menu:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    height: 100vh;*/
/*    width: 100vw;*/
/*    top: 0;*/
/*    right: 0;*/
/*    z-index: -2;*/
/*    opacity: 0;*/
/*    cursor: auto;*/
/*}*/

/*.amin-menu p {*/
/*    border-bottom: 1px solid #C7CFD6;*/
/*    color: #474747;*/
/*}*/

.admin {
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.admin p {
    margin: 0;
}

.admin-menu {
    min-width: 200px;
    position: absolute;
    top: 50px;
    right: 5px;
    background-color: #FAFBFB;
    padding: 15px 30px;
    border-radius: 4px;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-menu img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.admin-menu p {
    margin-bottom: 1rem;
}

.admin-menu:after {
    content: '';
    position: absolute;
    height: calc(100vh - 100px);
    width: 100vw;
    top: 0;
    right: 0;
    z-index: -2;
    opacity: 0;
    cursor: auto;
}

.admin-menu p {
    border-bottom: 1px solid #C7CFD6;
    color: #474747;
    text-align: center;
}

header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.main {
    display: flex;
    gap: 20px;
    padding-bottom: 100px;
}

.card-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: start;
    color: #474747;
    text-transform: uppercase;

}

.main-direction {
    box-sizing: border-box;
    background: #F5F7FA;
    border: 1px solid #2F80ED;
    border-radius: 8px;
    overflow: hidden;
    color: black;
    text-align: start;
}

.main-direction p, .main-direction h5 {
    padding: 0 20px;
}

.main-direction h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #474747;
}

.main-direction-header {
    box-sizing: border-box;
    background-color: #2F80ED;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.main-direction-header span {
    color: #FFF;
}

.main-direction ul {
    position: relative;
    list-style: none;
    text-align: start;
    padding: 0 25px;
}

.main-direction li {
    padding-bottom: 25px;
    margin-left: 25px;
    text-indent: -25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #757575;
}

.main-direction li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 30px;
    width: 1px;
    height: calc(100% - 70px);
    border-left: 1px dashed #2F80ED;
}

.ul-right li:not(:last-child)::after {
    height: calc(100% - 90px);
}

.main-direction li::before {
    content: '';
    background: #2F80ED;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    line-height: 5px;
    margin-right: 13px;
}

.logo {
    width: 300px;
}
.logo-efko {
    cursor: pointer;
}
table {
    width: 100%;
    background-color: #F0F1F1;
    border-collapse: collapse;
    border: 0.2px solid #C7CFD6;
}

tr {
    border-bottom: 1px solid #dcddec;
}

th, td {
    text-align: start !important;
    border: none;
    min-height: 50px;
    font-size: 14px;
    line-height: 17px;
    padding-left: 30px;
}

th {
    color: #474747;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1 {
    font-size: 1.25rem;
    font-weight: 500;
}


/* text field */
.text-field {
    margin-bottom: 1rem;
}

.text-field__label {
    display: block;
    margin-bottom: 0.25rem;
}

.text-field__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.text-field__input::placeholder {
    color: #212529;
    opacity: 0.4;
}

.text-field__input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #bdbdbd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
}

.text-field__input:disabled,
.text-field__input[readonly] {
    background-color: #f5f5f5;
    opacity: 1;
}

/* inactive icon - вариант 1 */
.text-field__icon {
    position: relative;
}

.text-field__icon::before {
    content: '';
    color: #bdbdbd;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
}

.text-field__icon input {
    padding-left: 2rem;
}

.text-field__icon_email::before {
    content: '@';
}

.text-field__icon_search::before {
    width: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23bdbdbd' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

/* inactive icon - вариант 2 */
.text-field__icon-2 {
    position: relative;
}

.text-field__icon-2::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2.5rem;
    background-color: #f5f5f5;
    border: 1px solid #bdbdbd;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.text-field__icon-2::after {
    content: '';
    color: #212529;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
}

.text-field__icon-2 input {
    padding-left: 3rem;
}

.text-field__icon-2_email::after {
    content: '@';
}

.text-field__icon-2_search::after {
    width: 2.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

.custom-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(217, 217, 217, 0.2);
    backdrop-filter: blur(20px);
}

.custom-modal p, h4 {
    text-align: center;
}

.custom-modal h4 {
    font-weight: 500 !important;
    font-style: normal;
    font-size: 18px !important;
    line-height: 16px;
    color: #474747;
    margin-bottom: 40px;
}

.custom-progress-bar {
    height: 5px;
    width: 380px;
    border-radius: 7px;
    padding: 0 !important;
    background-color: #E3E7EB;
}

.custom-progress-bar > div {
    padding: 0 !important;
    border-radius: 7px;
    height: 100%;
    background-color: #2F80ED;
}

.custom-modal-content {
    background-color: #FFF;
    /*height: 230px;*/
    /*width: 560px;*/
    border: 1px solid #2F80ED;
    border-radius: 10px;
    max-height: 98%;
}

.custom-modal-content p {
    text-align: center;
    max-width: 300px;
}

.custom-modal-content > div {
    padding: 20px 62px;
}

.custom-modal-content .icon-user {
    width: 106px;
    height: 106px;
    cursor: pointer;
}
.add-photo-icon {
    right: 0;
    bottom: 0;
}
.custom-modal input {
    width: 100%;
    padding: 8px 12px;
    background: rgba(233, 235, 235, 0.76);
    border: 1px solid rgba(206, 206, 206, 0.76);
    border-radius: 4px;
}

.custom-modal input:focus, .custom-modal input:focus-visible {
    /*border: 1px solid #2F80ED;*/
    outline: 1px solid #2F80ED;
}

.custom-modal-footer {
    min-height: 77px;
    background: #F5F7FA;
    display: flex;
    justify-content: center;
    gap: 40px;
    border-radius: 0 0 10px 10px;
}

.custom-modal-warning h4 {
    font-style: normal !important;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 16px;
    color: #474747;
}


/*.btn {*/
/*    display: inline-block;*/
/*    font-weight: 400;*/
/*    !*color: #212529;*!*/
/*    text-align: center;*/
/*    border: 1px solid transparent;*/
/*    padding: .375rem .75rem;*/
/*    font-size: 1rem;*/
/*    line-height: 1.5;*/
/*    border-radius: .25rem;*/
/*    color: #fff;*/
/*    background-color: #007bff;*/
/*    cursor: pointer;*/
/*    min-width: 140px;*/
/*}*/

/*.btn:hover {*/
/*    background-color: #0069d9 !important;*/
/*}*/

.btn-outline {
    background: #FFFFFF;
    border: 1px solid #474747;
    color: black;
}

.div-btn {
    margin-top: 20px;
    display: flex;
    justify-content: end;
}

.checkbox {
    display: flex;
    align-items: center;
    position: relative;
}

.checkbox input {

    visibility: hidden;
    height: 0;
    width: 0;
    opacity: 0;
}

.checkbox_label:before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #97BDD3;
    border-radius: 4px;
    margin-right: 15px;
    background-color: #FFF;
}

.checkbox input:checked ~ .checkbox_label:before {
    /*content: '\2714';*/
    text-align: center;
    /*vertical-align: middle;*/
    /*line-height: 17px;*/
    color: #0F6291;
}

.checkbox input:checked ~ .checkbox_label:after {
    position: absolute;
    content: '';
    text-align: center;
    width: 12px;
    height: 6px;
    transform: rotate(-45deg);
    border-left: 2px solid #0F6291;
    border-bottom: 2px solid #0F6291;
    left: 7px;
    top: 8px;
}


.checkbox_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}


.icon-user {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background-color: #D8D8D8;
    border-radius: 50%;
}

.input-changed {
    border: none;
    background: inherit;
    width: 100%;
    padding: 4px;
}
.warning-text {
    height: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #EC0F72;
}
.btn-close {
    width: 30px;
    height: auto;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}